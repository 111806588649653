<template>
  <div>
    <div class="container-home">
      <div
        :class="`row bg-${
          $q.dark.isActive ? 'dark' : 'white'
        } q-pa-md grid-menu`"
      >
        <q-card
          v-for="item in rotasVisiveis"
          :key="item.id"
          class="full-width col-2 q-pa-md cursor-pointer card"
          :class="{ 'card-dark': $q.dark.isActive, card: !$q.dark.isActive }"
          @click="() => $router.push(item.path)"
        >
          <div class="row items-center justify-between">
            <q-icon
              :name="item.icon"
              size="2rem"
              :class="`text-${$q.dark.isActive ? 'white' : 'grey-8'}`"
            />
            <q-icon name="chevron_right" class="text-primary" size="2rem" />
          </div>
          <p
            :class="`title-menu q-pt-xl q-mb-none text-weight-medium text-${
              $q.dark.isActive ? 'white' : 'grey-8'
            }`"
          >
            {{ item.name }}
          </p>
        </q-card>
        <q-card
          class="full-width col-2 q-pa-md cursor-pointer card"
          :class="{ 'card-dark': $q.dark.isActive, card: !$q.dark.isActive }"
          ripple
          @click="abrirLoja"
        >
          <div class="row items-center justify-between">
            <q-icon
              name="store"
              size="2rem"
              :class="`text-${$q.dark.isActive ? 'white' : 'grey-8'}`"
            />
            <q-icon name="chevron_right" class="text-primary" size="2rem" />
          </div>
          <p
            :class="`q-pt-xl q-mb-none title-menu text-weight-medium text-${
              $q.dark.isActive ? 'white' : 'grey-8'
            }`"
          >
            Nosso Site
          </p>
        </q-card>
      </div>
      <div
        :class="`row bg-${
          $q.dark.isActive ? 'dark' : 'white'
        } q-px-md q-pb-lg q-pt-md container-exit`"
      >
        <q-btn
          outline
          label="Sair"
          class="text-negative exit-button"
          @click="logout"
          icon="exit_to_app"
        />
      </div>
    </div>
  </div>
</template>

<script>
import login from "@/plugins/login";
import ParametrosServices from "@/services/parametros";

export default {
  name: "Home",

  data() {
    return {
      habilitaMeuBonus: true,
    };
  },

  created() {
    this.buscaParametros();
  },

  computed: {
    rotas() {
      const rotasFiltradas = this.$router.options.routes[0].children.filter(
        (el) =>
          !el.hidden ||
          (el.desabilitaDependente &&
            Boolean(!login.usuarioLogado().id_dependente))
      );

      const rotasAtualizadas = rotasFiltradas.map((rota) => {
        if (rota.path === "/meu-bonus" && !this.habilitaMeuBonus) {
          return { ...rota, hidden: true };
        }
        return rota;
      });

      return rotasAtualizadas;
    },

    rotasVisiveis() {
      return this.rotas.filter((rota) => !rota.hidden);
    },
  },

  methods: {
    async buscaParametros() {
      try {
        const [{ data: habilitaMeuBonus }] = await Promise.all([
          ParametrosServices.getParametro("AREA_CLIENTE.HABILITA_MEU_BONUS"),
        ]);
        this.habilitaMeuBonus = Boolean(Number(habilitaMeuBonus[0].valor));
      } catch (error) {
        console.error("Erro ao buscar parâmetros:", error);
      }
    },

    abrirLoja() {
      window.open(process.env.VUE_APP_URL_ECOMMERCE);
    },

    logout() {
      login.logout();
    },
  },
};
</script>

<style>
.card:hover {
  background-color: #fafafa;
  transition: ease-in-out 0.3s;
}

.card-dark:hover {
  background-color: #212121;
  transition: ease-in-out 0.3s;
}

.container-home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-menu {
  font-size: 20px;
}

.exit-button {
  width: 150px;
}

.grid-menu {
  width: 100%;
  max-width: 1200px;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}

.container-exit {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 600px) {
  .grid-menu {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 730px) {
  .grid-menu {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 920px) {
  .grid-menu {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1100px) {
  .grid-menu {
    grid-template-columns: repeat(6, 1fr);
  }
}
</style>
